<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? Ncr__title : ''" help-page-component="AddNcr"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <NcrAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="add"
                    v-if="addMode && newContractRequestUnsaved"
                ></NcrAlert>
                <NcrAlert
                    :alert="$t('message.closeAlert')"
                    type="warning"
                    class="mt-3"
                    mode="update"
                    v-if="updateMode && updatedContractRequestUnsaved"
                >
                </NcrAlert>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="ncrForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            multiple
                            v-model="panel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row class="pt-3" v-if="updateMode">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractRequestNo') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ Ncr__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row :class="addMode ? 'pt-3' : ''">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="ncrPreferredEtdMenu"
                                                transition="scale-transition"
                                                v-model="ncrDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :label="Ncr__ncrdate ? '' : $t('message.required')"
                                                        :rules="[rules.date]"
                                                        :value="computedNcrDateFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Ncr__ncrdate = null"
                                                        @click:append="ncrDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Ncr__ncrdate"
                                                    @input="ncrDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesColleague') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items="allSalesColleagues"
                                                :label="Ncr__salescontact_id ? '' : $t('message.required')"
                                                :rules="[rules.salesColleague]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Salesperson.name"
                                                item-value="Salesperson.id"
                                                required
                                                solo
                                                v-if="addMode"
                                                v-model="Ncr__salescontact_id"
                                                @change="salesColleagueChanged()"
                                            >
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-avatar>
                                                            <img :src="data.item.User.photo ? ('data:image/jpeg;base64,' + data.item.User.photo) : '/static/avatars/appic_user.jpg'" alt="avatar" height="35" width="35" class="img-responsive rounded-circle">
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.Salesperson.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-html="data.item.Country.name"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                            <span v-if="updateMode" class="font-weight-black">{{ Ncr__Salescontact__name }}</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="addMode">
                                        <v-flex xs6 lg5 md6 py-3 offset-lg-2 offset-md-3 offset-xs-6 class="align-center">
                                            <div class="d-flex flex-row">
                                                <v-btn
                                                    :loading="loading.contracts"
                                                    :disabled="loading.contracts"
                                                    @click="openSelectContractDialog()"
                                                    color="success"
                                                    small
                                                >{{ $t('message.copyPreviousContract') }}</v-btn>
                                                <span class="mx-3">{{ $t('message.or') }}</span>
                                                <v-btn
                                                    :loading="loading.ncrs"
                                                    :disabled="loading.ncrs"
                                                    @click="openSelectNcrDialog()"
                                                    color="success"
                                                    small
                                                >{{ $t('message.copyPreviousNcr') }}</v-btn>
                                            </div>
                                            <div class="mt-3">
                                                <span>{{ $t('message.orEnterContractDetailsManuallyBelow') }}</span>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="filteredSalestypes"
                                                :label="Ncr__salestype_id ? '' : $t('message.required')"
                                                :rules="[rules.salesType]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Salestype.title"
                                                item-value="Salestype.id"
                                                solo
                                                required
                                                v-model="Ncr__salestype_id"
                                                @change="salesTypeChanged($event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :disabled="Ncr__salescontact_id == null"
                                                :items="filteredBuyers"
                                                :label="Ncr__customer_id ? '' : (Ncr__salescontact_id == null ? $t('message.selectSalesColleagueFirst') : $t('message.required'))"
                                                :loading="loading.buyers"
                                                :rules="[rules.buyer]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Customer.otsname"
                                                item-value="Customer.id"
                                                required
                                                solo
                                                v-model="Ncr__customer_id"
                                                @change="buyerChanged()"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ Ncr__salestype_id != 5 ? $t('message.seller') : $t('message.appTimberCompany') }}
                                        </v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="filteredOffices"
                                                :label="Ncr__office_id ? '' : $t('message.required')"
                                                :rules="[rules.office]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Office.title"
                                                item-value="Office.id"
                                                required
                                                solo
                                                v-model="Ncr__office_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ Ncr__salestype_id == 5 ? $t('message.seller') : $t('message.preferredSupplier') }}
                                        </v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items="allSuppliers"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Supplier.otsname"
                                                item-value="Supplier.id"
                                                required
                                                solo
                                                v-model="Ncr__supplier_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.countryOfOrigin') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items="allCountries"
                                                :label="Ncr__productorigin_id ? '' : $t('message.required')"
                                                :rules="[rules.origin]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Country.name"
                                                item-value="Country.id"
                                                required
                                                solo
                                                v-model="Ncr__productorigin_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.destination') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items="allCountries"
                                                :label="Ncr__destination_id ? '' : $t('message.required')"
                                                :rules="[rules.destination]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Country.name"
                                                item-value="Country.id"
                                                required
                                                solo
                                                v-model="Ncr__destination_id"
                                                @change="checkEutrComplianceRequired($event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eutrComplianceRequired') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                class="mt-0"
                                                dense
                                                hide-details="auto"
                                                v-model="Ncr__eutr_compliance_required"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.incoterm') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="filteredIncoterms"
                                                :label="Ncr__incoterm_id ? '' : $t('message.required')"
                                                :rules="[rules.incoterm]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-value="Incoterm.id"
                                                item-text="Incoterm.title"
                                                required
                                                solo
                                                v-model="Ncr__incoterm_id"
                                                @change="incotermChanged($event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Ncr__incoterm_id == 1">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pol') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :key="'ncr-loading-port'"
                                                :items="allShippingDischargePorts"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Shippingport.title"
                                                item-value="Shippingport.id"
                                                solo
                                                required
                                                v-model="Ncr__loadingport_id"
                                            >
                                                <template v-slot:selection="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                                <template v-slot:item="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pod') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :key="'ncr-discharge-port'"
                                                :items="allShippingDischargePorts"
                                                :label="Ncr__shippingport_id ? '' : $t('message.required')"
                                                :rules="[rules.pod]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Shippingport.title"
                                                item-value="Shippingport.id"
                                                solo
                                                required
                                                v-model="Ncr__shippingport_id"
                                            >
                                                <template v-slot:selection="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                                <template v-slot:item="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerPaymentTerms') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :key="'ncr-payment-term'"
                                                :items="allBuyerPaymentTerms"
                                                :label="Ncr__paymentstatement_id ? '' : $t('message.required')"
                                                :rules="[rules.buyerTerm]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-value="Paymentterm.id"
                                                item-text="Paymentterm.title"
                                                required
                                                solo
                                                v-model="Ncr__paymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierPaymentTerms') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items="allSupplierPaymentTerms"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-value="Paymentterm.id"
                                                item-text="Paymentterm.title"
                                                solo
                                                v-model="Ncr__popaymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.suggestedETD') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="ncrPreferredEtdMenu"
                                                transition="scale-transition"
                                                v-model="ncrPreferredEtdMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedNcrPreferredEtdFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hide-details="auto"
                                                        hint="DD/MM/YYYY format"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Ncr__etddate = null"
                                                        @click:append="ncrPreferredEtdMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Ncr__etddate"
                                                    @input="ncrPreferredEtdMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.shippingWeek') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-combobox
                                                :items="sortedCalendarWeeks"
                                                :return-object="false"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-value="Calendarweek.title"
                                                item-text="Calendarweek.title"
                                                solo
                                                v-model="Ncr__shippingweek"
                                            >
                                                <template v-slot:item="{item}">
                                                    <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                                        <div class="d-flex flex-column" style="width: 100% !important;">
                                                            <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                                            <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                                        </div>
                                                    </template>
                                                    <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                                        <template v-if="[52,53].includes(item.Calendarweek.id)">
                                                            <div class="d-flex flex-column" style="width: 100% !important;">
                                                                <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                                                <v-divider></v-divider>
                                                                <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                                        </template>
                                                    </template>
                                                    <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                                        <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                                    </template>
                                                </template>
                                            </v-combobox>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredEta') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                max-width="290px"
                                                min-width="290px"
                                                ref="ncrPreferredEtaMenu"
                                                transition="scale-transition"
                                                v-model="ncrPreferredEtaMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        :value="computedNcrPreferredEtaFormatted"
                                                        append-icon="event"
                                                        clearable
                                                        class="force-text-left"
                                                        hint="DD/MM/YYYY format"
                                                        hide-details="auto"
                                                        background-color="white"
                                                        persistent-hint
                                                        readonly
                                                        solo
                                                        v-on="on"
                                                        @click:clear="Ncr__etadate = null"
                                                        @click:append="ncrPreferredEtaMenu = true"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    first-day-of-week="1"
                                                    locale-first-day-of-year="4"
                                                    show-week
                                                    v-model="Ncr__etadate"
                                                    @input="ncrPreferredEtaMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.containers') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <div class="d-flex flex-row">
                                                <v-text-field
                                                    :counter="false"
                                                    :value="Ncr__containercount"
                                                    :label="Ncr__containercount ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    min="1"
                                                    solo
                                                    type="number"
                                                    @change="Ncr__containercount = $event"
                                                >
                                                </v-text-field>
                                                <v-icon small class="mt-1 mx-2">mdi-close</v-icon>
                                                <v-select
                                                    :items="containerSizes"
                                                    :label="Ncr__containersize_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Containersize.title"
                                                    item-value="Containersize.id"
                                                    solo
                                                    v-model="Ncr__containersize_id"
                                                >
                                                </v-select>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="certifications"
                                                :label="Ncr__fsc ? '' : $t('message.required')"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Certification.title"
                                                item-value="Certification.id"
                                                required
                                                solo
                                                v-model="Ncr__fsc"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items="productGroups"
                                                :label="Ncr__productgroup_id ? '' : $t('message.required')"
                                                :rules="[rules.product]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Productgroup.title"
                                                item-value="Productgroup.id"
                                                solo
                                                v-model="Ncr__productgroup_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                            <v-radio-group
                                                class="mt-0"
                                                dense
                                                hide-details="auto"
                                                row
                                                v-model="Ncr__ms_id"
                                            >
                                                <v-radio
                                                    :label="$t('message.metric')"
                                                    :value="1"
                                                ></v-radio>
                                                <v-radio
                                                    :label="$t('message.imperial')"
                                                    :value="2"
                                                ></v-radio>
                                            </v-radio-group>
                                            <div class="font-sm font-italic">({{ $t('message.switchToFilterThicknessAndUnits') }})</div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.project') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                            <v-checkbox
                                                :label="$t('message.export')"
                                                :true-value="1"
                                                class="mt-0"
                                                dense
                                                hide-details="auto"
                                                v-model="Ncr__is_export"
                                            />
                                            <v-checkbox
                                                :label="$t('message.others')"
                                                :true-value="1"
                                                class="mt-0 ml-4"
                                                dense
                                                hide-details="auto"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="updateMode">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.status') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1><span :class="Ncr__status_id == 3 ? 'green--text lighten-1 font-weight-bold' : ''">{{ getStatus(Ncr__status_id) }}</span></v-flex>
                                    </v-layout>
                                    <v-layout row v-if="updateMode && Ncr__contract_id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedContract') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <router-link :to="{name: 'update_contract', params: { contractId: Ncr__contract_id }}" class="text-no-wrap">{{ Contract__title }}</router-link>
                                        </v-flex>
                                    </v-layout>
                                    <NcrItemListV3
                                        :item-list-key="itemListKey"
                                        :items-copied="itemsCopied"
                                        :items-loaded="itemsLoaded"
                                        :update-mode="updateMode"
                                        :validate.sync="validateNcrItems"
                                        :reload-descriptions.sync="reloadDescriptions"
                                        @items-invalid="setItemsValidity(false)"
                                        @items-valid="setItemsValidity(true)"
                                        @descriptions-reloaded="reloadDescriptions = false"
                                    />
                                    <div class="pl-3">{{ $t('message.remarks') + ' (' + $t('message.shownInContract') + ')' }}</div>
                                    <v-lazy>
                                        <v-textarea
                                            :value="Ncr__remarks"
                                            auto-grow
                                            clearable
                                            class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                            dense
                                            hide-details="auto"
                                            key="ncr-contract-remarks"
                                            rows="1"
                                            solo
                                            @change="Ncr__remarks = $event"
                                        />
                                    </v-lazy>
                                    <div class="pl-3 pt-3">{{ $t('message.remarksInternal') }}</div>
                                    <v-lazy>
                                        <v-textarea
                                            :value="Ncr__internal_remarks"
                                            auto-grow
                                            clearable
                                            class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                            dense
                                            hide-details="auto"
                                            key="ncr-internal-remarks"
                                            rows="1"
                                            solo
                                            @change="Ncr__internal_remarks = $event"
                                        />
                                    </v-lazy>
                                    <div class="pl-3 pt-3">{{ $t('message.gradeRemarks') + ' ('+ $t('message.internal') + ')' }}</div>
                                    <v-lazy>
                                        <v-textarea
                                            :value="Ncr__grade_remarks"
                                            auto-grow
                                            clearable
                                            class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                            dense
                                            hide-details="auto"
                                            key="ncr-grade-remarks"
                                            rows="1"
                                            solo
                                            @change="Ncr__grade_remarks = $event"
                                        />
                                    </v-lazy>
                                    <div class="pl-3 pt-3">{{ $t('message.sizeRemarks') + ' ('+ $t('message.internal') + ')' }}</div>
                                    <v-lazy>
                                        <v-textarea
                                            :value="Ncr__size_remarks"
                                            auto-grow
                                            clearable
                                            class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                            dense
                                            hide-details="auto"
                                            key="ncr-size-remarks"
                                            rows="1"
                                            solo
                                            @change="Ncr__size_remarks = $event"
                                        />
                                    </v-lazy>
                                    <v-layout row>
                                        <v-flex d-flex class="align-center">
                                            <v-layout row pl-3 pt-4 mb-0 ref="actionsRow">
                                                <v-btn
                                                    class="ml-3"
                                                    small
                                                    @click.stop="previewDialog = true"
                                                    v-if="false"
                                                >{{ $t('message.preview') }}
                                                </v-btn>
                                                <PrintNcrButton
                                                    :update-mode="updateMode"
                                                    :ncr-id="Ncr__id"
                                                    :list-mode="false"
                                                />
<!--                                                <v-btn-->
<!--                                                    small-->
<!--                                                    class="ml-3 lighten-2"-->
<!--                                                    v-if="updateMode"-->
<!--                                                    @click.stop="viewPdf()"-->
<!--                                                >{{ $t('message.preview') }}-->
<!--                                                </v-btn>-->
                                                <v-btn
                                                    :loading="loading.add"
                                                    class="ml-3"
                                                    color="info"
                                                    small
                                                    @click="addNcr()"
                                                    v-if="addMode == true && $can('create','Ncr')"
                                                >{{ $t('message.create') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.save"
                                                    class="ml-3"
                                                    color="info"
                                                    small
                                                    @click="saveNcr()"
                                                    v-if="updateMode == true && [1,2].includes(Ncr__status_id) && $can('update','Ncr')"
                                                >
                                                    {{ $t('message.save') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.save"
                                                    class="ml-3"
                                                    color="info"
                                                    small
                                                    @click="saveNcr()"
                                                    v-if="updateMode == true && [3].includes(Ncr__status_id) && $can('update','ApprovedNcr') && Ncr__contract_id == null"
                                                >
                                                    {{ $t('message.save') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.cancel"
                                                    class="ml-3"
                                                    color="error"
                                                    small
                                                    v-if="updateMode == true && [1].includes(Ncr__status_id) && $can('delete','Ncr')"
                                                    @click="cancelNcr(Ncr__id)"
                                                >
                                                    {{ $t('message.cancel') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.submit"
                                                    class="ml-3"
                                                    color="warning"
                                                    small
                                                    v-if="updateMode == true && Ncr__status_id == 1 && $can('update','Ncr')"
                                                    @click="submitNcrForApproval(Ncr__id)"
                                                >{{ $t('message.submitToOperations') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.approve"
                                                    color="success"
                                                    class="ml-3"
                                                    small
                                                    v-if="updateMode == true && Ncr__status_id == 2 && $can('approve','Ncr')"
                                                    @click="approveNcr(Ncr__id)"
                                                >{{ $t('message.approve') }}
                                                </v-btn>
                                                <v-btn
                                                    :loading="loading.reject"
                                                    class="ml-3"
                                                    color="error"
                                                    small
                                                    v-if="updateMode == true && Ncr__status_id == 2 && $can('approve','Ncr')"
                                                    @click="rejectNcr(Ncr__id)"
                                                >{{ $t('message.reject') }}
                                                </v-btn>
                                                <v-btn
                                                    class="ml-3"
                                                    color="info"
                                                    small
                                                    v-if="Ncr__status_id == 3 && Ncr__contract_id == null && $can('approve','Ncr')"
                                                    @click.stop="convertDialog = true"
                                                >{{ $t('message.convertToContract') }}
                                                </v-btn>
                                                <v-btn
                                                    class="ml-3"
                                                    small
                                                    v-if="addMode == false && [2].includes(Ncr__status_id) && $can('create','Ncr')"
                                                    @click="newNcr()"
                                                >{{ $t('message.createAnotherRequest') }}</v-btn>
<!--                                                <v-btn-->
<!--                                                    class="ml-3"-->
<!--                                                    small-->
<!--                                                    @click="ncrList()"-->
<!--                                                    v-if="addMode == false && [1,2,3].includes(Ncr__status_id) && $can('access','Ncrs')"-->
<!--                                                >{{ $t('message.seeRequestsList') }}</v-btn>-->
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.operationsRemarks') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <NcrRemarks :ncr-id="Ncr__id" :ncr-status-id="Ncr__status_id"></NcrRemarks>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_ncr')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="ncrs" :asset_id="Ncr__id"></DocumentHistory>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        >
        </SimpleAlert>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-model="previewDialog"
            v-if="false"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.contractRequestPreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <ContractRequestPreview></ContractRequestPreview>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="previewDialog = false"
                    >{{ [1,2].includes(Ncr__status_id) ? $t('message.backToEditing') : $t('message.close') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.add"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == true && $can('create','Ncr')"
                        @click="addNcr()"
                    >{{ $t('message.create') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.save"
                        class="ml-3"
                        color="info"
                        small
                        v-if="addMode == false && [1,2].includes(Ncr__status_id) && $can('update','Ncr')"
                        @click="saveNcr()"
                    >{{ $t('message.save') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.save"
                        class="ml-3"
                        color="info"
                        small
                        v-if="updateMode == true && [3].includes(Ncr__status_id) && $can('update','ApprovedNcr') && Ncr__contract_id == null"
                        @click="saveNcr()"
                    >{{ $t('message.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            scrollable
            transition="dialog-top-transition"
            v-model="selectContractDialog"
            width="1200px"
            max-width="1200px !important"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectContract') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-overlay
                        :value="loading.contracts"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :footer-props="{
                            itemsPerPageOptions: [10,20,50,-1],
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                        :headers="contractHeaders"
                        :items="filteredContracts"
                        :search="searchTerm"
                        :options.sync="tableOptions"
                        calculate-widths
                        class="appic-table-light specification-table"
                        dense
                        item-key="Contract.id"
                        show-select
                        single-select
                        v-model="selectedContract"
                        id="contract_list"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="6" class="d-flex flex-row col-lg-6 col-sm-12 offset-lg-6 align-center pb-3">
                                    <v-text-field
                                        :label="$t('message.filterResults')"
                                        :value="searchTerm"
                                        autocomplete="off"
                                        class="force-text-left mt-2 mb-0"
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        hide-details
                                        @change="searchTerm = $event"
                                    >
                                    </v-text-field>
                                    <v-btn
                                        :loading="loading.filterResults"
                                        class="ml-3"
                                        small
                                        @click="filterResults"
                                    >{{ $t('message.filter') }}
                                    </v-btn>
                                    <v-btn
                                        class="ml-3"
                                        small
                                        @click="searchTerm = null"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:header.data-table-select="{ on, props }">
                            <v-simple-checkbox
                                v-bind="props"
                                v-on="on"
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.data-table-select="{ isSelected, select, item }">
                            <div class="pt-1 text-center">
                                <v-simple-checkbox
                                    :value="isSelected"
                                    @input="select($event)"
                                ></v-simple-checkbox>
                            </div>
                        </template>
                        <template v-slot:item.Contract.title="{ item }">
                            <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                        </template>
                        <template v-slot:item.Contract.contractdate="{ item }">
                            {{ item.Contract.contractdate != '0000-00-00' ? formatDate(item.Contract.contractdate) : '' }}
                        </template>
                        <template v-slot:item.Contract.Customer.title="{ item }">
                            {{ item.Contract.Customer.otsname != '' ? item.Contract.Customer.otsname : item.Contract.Customer.title }}
                        </template>
                        <template v-slot:item.Contract.Supplier.title="{ item }">
                            <span>{{ item.Contract.Supplier.code != '' ? item.Contract.Supplier.code : item.Contract.Supplier.title }}</span>
                        </template>
                        <template v-slot:item.Contract.species="{ item }">
                            <div class="text-no-wrap truncate" v-if="item.Contract.version == null">{{ item.Contract.species }}</div>
                            <div v-if="item.Contract.version != null">
                                <div :class="JSON.parse(item.Contract.species_ids).length > 1 ? 'font-xs ' : ''" v-for="species in contractSpecies(JSON.parse(item.Contract.species_ids)) ">{{ species }}</div>
                                <div v-if="item.Contract.species_ids == null">{{ item.Contract.species }}</div>
                            </div>
                        </template>
                        <template v-slot:item.Contract.containersize_id="{ item }">
                            <div class="text-no-wrap">
                                <span v-if=" [1,2,3,5].includes(item.Contract.containersize_id) ">{{ item.Contract.containercount }}</span>
                                <span v-if=" [1,2,3,5].includes(item.Contract.containersize_id) "> X </span>
                                <span>{{ getContainerSize(item.Contract.containersize_id) }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Contract.cost="{ item }">
                            <div class="flex-row">
                                <span class="pr-1">{{ getCurrency(item.Contract.currency_id) }}</span>
                                <span class="text-right">{{ formatThisNumber(item.Contract.cost) }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Contract.amount="{ item }">
                            <div class="flex-row text-left">
                                <span class="pr-1">{{ getCurrency(item.Contract.currency_id) }}</span>
                                <span class="text-right">{{ formatThisNumber(item.Contract.amount) }}</span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="selectContractDialog = false"
                    >
                        {{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :disabled="selectedContract.length == 0"
                        :loading="loading.copy"
                        color="success lighten-1"
                        small
                        @click="copyContract()"
                    >
                        {{ $t('message.copy') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-if="Ncr__status_id == 3"
            v-model="convertDialog"
            width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.ncrConvertThisToContract') }}</v-card-title>
                <v-card-text>
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="4">{{ $t('message.newContractNumber') }}</v-col>
                        <v-col cols="8">
                            <v-text-field
                                :value="newContractNo"
                                autocomplete="new-password"
                                class="force-text-left"
                                dense
                                hide-details
                                solo
                                @change="newContractNo = $event"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-3">
                        <v-col class="red--text font-italic">{{ $t('message.contractConvertNote') }}</v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="convertDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.convert"
                        class="ml-3"
                        color="info"
                        small
                        @click="convertNcrToContract(Ncr__id)"
                    >{{ $t('message.convert') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            scrollable
            transition="dialog-top-transition"
            v-model="selectNcrDialog"
            width="1200px"
            max-width="1200px !important"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectNcr') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-overlay
                        :value="loading.ncrs"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :footer-props="{
                            itemsPerPageOptions: [10,20,50,-1],
                            showCurrentPage: true,
                            showFirstLastPage: true
                        }"
                        :headers="ncrHeaders"
                        :items="filteredNcrs"
                        :search="searchTerm"
                        :options.sync="tableOptions"
                        calculate-widths
                        class="appic-table-light specification-table"
                        dense
                        item-key="Ncr.id"
                        show-select
                        single-select
                        v-model="selectedNcr"
                        id="ncr_list"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="6" class="d-flex flex-row col-lg-6 col-sm-12 offset-lg-6 align-center pb-3">
                                    <v-text-field
                                        :label="$t('message.filterResults')"
                                        :value="searchNcrTerm"
                                        autocomplete="off"
                                        class="force-text-left mt-2 mb-0"
                                        dense
                                        prepend-inner-icon="mdi-filter-outline"
                                        hide-details
                                        @change="searchNcrTerm = $event"
                                    >
                                    </v-text-field>
                                    <v-btn
                                        :loading="loading.filterNcrResults"
                                        class="ml-3"
                                        small
                                        @click="filterResults"
                                    >{{ $t('message.filter') }}
                                    </v-btn>
                                    <v-btn
                                        class="ml-3"
                                        small
                                        @click="searchNcrTerm = null"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:header.data-table-select="{ on, props }">
                            <v-simple-checkbox
                                v-bind="props"
                                v-on="on"
                            ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.data-table-select="{ isSelected, select, item }">
                            <div class="pt-1 text-center">
                                <v-simple-checkbox
                                    :value="isSelected"
                                    @input="select($event)"
                                ></v-simple-checkbox>
                            </div>
                        </template>
                        <template v-slot:item.Ncr.title="{ item }">
                            <span class="font-weight-bold">{{ item.Ncr.title }}</span>
                        </template>
                        <template v-slot:item.Ncr.ncrdate="{ item }">
                            {{ item.Ncr.ncrdate != '0000-00-00' ? formatDate(item.Ncr.ncrdate) : '' }}
                        </template>
                        <template v-slot:item.Ncr.Customer.title="{ item }">
                            {{ item.Ncr.Customer.otsname != '' ? item.Ncr.Customer.otsname : item.Ncr.Customer.title }}
                        </template>
                        <template v-slot:item.Ncr.Supplier.title="{ item }">
                            <span>{{ item.Ncr.Supplier.code != '' ? item.Ncr.Supplier.code : item.Ncr.Supplier.title }}</span>
                        </template>
                        <template v-slot:item.Ncr.species="{ item }">
                            <div v-for="item in item.Ncr.Specifications">{{ item.species }}</div>
                        </template>
                        <template v-slot:item.Ncr.grade="{ item }">
                            <div v-for="item in item.Ncr.Specifications">{{ item.grade }}</div>
                        </template>
                        <template v-slot:item.Ncr.size="{ item }">
                            <div v-for="item in item.Ncr.Specifications">{{ item.size }}</div>
                        </template>
                        <template v-slot:item.Ncr.containersize_id="{ item }">
                            <div class="text-no-wrap">
                                <span v-if=" [1,2,3,5].includes(item.Ncr.containersize_id) ">{{ item.Ncr.containercount }}</span>
                                <span v-if=" [1,2,3,5].includes(item.Ncr.containersize_id) "> X </span>
                                <span>{{ getContainerSize(item.Ncr.containersize_id) }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Ncr.cost="{ item }">
                            <div class="flex-row">
                                <span class="pr-1">{{ getCurrency(item.Ncr.currency_id) }}</span>
                                <span class="text-right">{{ formatThisNumber(item.Ncr.cost) }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Ncr.amount="{ item }">
                            <div class="flex-row text-left">
                                <span class="pr-1">{{ getCurrency(item.Ncr.currency_id) }}</span>
                                <span class="text-right">{{ formatThisNumber(item.Ncr.amount) }}</span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="selectNcrDialog = false"
                    >
                        {{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :disabled="selectedNcr.length == 0"
                        :loading="loading.copyNcr"
                        color="success lighten-1"
                        small
                        @click="copyNcr()"
                    >
                        {{ $t('message.copy') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {mapFields} from "vuex-map-fields";
    import {mapActions, mapGetters} from "vuex";
    import {
        formatDate,
        getCurrentWeek,
        getCurrentYear,
        getDateRangeOfWeek,
        getDateRangeOfWeekWithYear,
        getNextYear,
        log,
        numberFormat,
        scrollToFirstFormValidationError,
        yearHasExtraWeek
    } from "Helpers/helpers";
    // import NcrItemList from "Components/Appic/NcrItemList";
    // import SimpleAlert from "Components/Appic/SimpleAlert";
    import {api} from "Api";
    // import NcrRemarks from "Components/Appic/NcrRemarks";
    import router from "@/router";
    import {v4 as uuidv4} from "uuid";
    import NcrAlert from "Components/Appic/NcrAlert";
    // import NcrItemListNew from "Components/Appic/NcrItemListNew";
    // import NcrItemListV2 from "Components/Appic/NcrItemListV2";
    import NcrItemListV3 from "Components/Appic/NcrItemListV3";
    import PrintNcrButton from "Components/Appic/Printing/PrintNcrButton";
    // import DocumentHistory from "Components/Appic/DocumentHistory";
    // import ContractRequestPreview from "Components/Appic/ContractRequestPreview";

    const NcrRemarks = () => import("Components/Appic/NcrRemarks");
    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const ContractRequestPreview = () => import("Components/Appic/ContractRequestPreview");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");

    export default {
        name: "AddNcr",
        components: {
            PrintNcrButton,
            // NcrItemListNew,
            NcrAlert,
            // NcrItemListV2,
            NcrItemListV3,
            ContractRequestPreview,
            DocumentHistory,
            NcrRemarks,
            SimpleAlert
            // NcrItemList
        },
        props: ['buyerId','ncrId','salesContactId'],
        title: 'NCR: ',
        data(){
            return {
                convertDialog: false,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                filteredBuyers: [],
                filteredContracts: [],
                filteredNcrs: [],
                filteredSalestypes: [
                    {Salestype: {id: 1, title: 'Direct Sales', code: 'DIR'}},
                    {Salestype: {id: 2, title: 'Commission Sales', code: 'COM'}},
                    {Salestype: {id: 5, title: 'Serviced Sales', code: 'SS'}},
                ],
                incotermports: [
                    {value: null, title: 'None'},
                    {value: 0, title: 'POD'},
                    {value: 1, title: 'POL'},
                    {value: 2, title: this.$t('message.doNotShow')},
                ],
                itemListKey: 0,
                itemsCopied: false,
                itemsLoaded: false,
                loading: {
                    add: false,
                    approve: false,
                    buyers: false,
                    contracts: false,
                    copy: false,
                    copyNcr: false,
                    convert: false,
                    filterResults: null,
                    filterNcrResults: null,
                    ncrs: false,
                    reset: false,
                    reject: false,
                    save: false,
                    submit: false,
                },
                loader: false,
                ncrDateMenu: false,
                ncrPreferredEtaMenu: false,
                ncrPreferredEtdMenu: false,
                newContractNo: null,
                panel: [0],
                pageKey: 0,
                previewDialog: false,
                reloadDescriptions: false,
                rules: {
                    buyer: v => !!v || this.$t('message.required'),
                    buyerTerm: v => !!v || this.$t('message.required'),
                    currency: v => !!v || this.$t('message.required'),
                    date: v => !!v || this.$t('message.required'),
                    destination: v => !!v || this.$t('message.required'),
                    incoterm: v => !!v || this.$t('message.required'),
                    incotermPort: v => typeof v === "number" || this.$t('message.required'),
                    office: v => !!v || this.$t('message.required'),
                    origin: v => !!v || this.$t('message.required'),
                    pod: v => !!v || this.$t('message.required'),
                    product: v => !!v || this.$t('message.required'),
                    salesColleague: v => !!v || this.$t('message.required'),
                    salesType: v => !!v || this.$t('message.required'),
                    supplier: v => !!v || this.$t('message.required'),
                    supplierTerm: v => !!v || this.$t('message.required')
                },
                searchNcrTerm: null,
                searchTerm: null,
                selectContractDialog: false,
                selectNcrDialog: false,
                selectedContract: [],
                selectedNcr: [],
                tableOptions: {
                    page: 1
                },
                validateNcrItems: false,
                validNcrItems: false,
                validForm: true
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('ncr',{
                Ncr__Salescontact__name: 'Ncr.Salescontact.name',
                Ncr__containercount: 'Ncr.containercount',
                Ncr__containersize_id: 'Ncr.containersize_id',
                Ncr__contract_id: 'Ncr.contract_id',
                Ncr__currency_id: 'Ncr.currency_id',
                Ncr__customer_id: 'Ncr.customer_id',
                Ncr__destination_id: 'Ncr.destination_id',
                Ncr__etadate: 'Ncr.etadate',
                Ncr__etddate: 'Ncr.etddate',
                Ncr__eutr_compliance_required: 'Ncr.eutr_compliance_required',
                Ncr__fsc: 'Ncr.fsc',
                Ncr__grade_remarks: 'Ncr.grade_remarks',
                Ncr__id: 'Ncr.id',
                Ncr__incoterm_id: 'Ncr.incoterm_id',
                Ncr__incotermport: 'Ncr.incotermport',
                Ncr__internal_remarks: 'Ncr.internal_remarks',
                Ncr__is_export: 'Ncr.is_export',
                Ncr__loadingport_id: 'Ncr.loadingport_id',
                Ncr__ms_id: 'Ncr.ms_id',
                Ncr__ncrdate: 'Ncr.ncrdate',
                Ncr__office_id: 'Ncr.office_id',
                Ncr__paymentstatement_id: 'Ncr.paymentstatement_id',
                Ncr__popaymentstatement_id: 'Ncr.popaymentstatement_id',
                Ncr__productgroup_id: 'Ncr.productgroup_id',
                Ncr__productorigin_id: 'Ncr.productorigin_id',
                Ncr__remarks: 'Ncr.remarks',
                Ncr__salescontact_id: 'Ncr.salescontact_id',
                Ncr__salestype_id: 'Ncr.salestype_id',
                Ncr__shippingport_id: 'Ncr.shippingport_id',
                Ncr__shippingweek: 'Ncr.shippingweek',
                Ncr__size_remarks: 'Ncr.size_remarks',
                Ncr__status_id: 'Ncr.status_id',
                Ncr__supplier_id: 'Ncr.supplier_id',
                Ncr__title: 'Ncr.title',
                Contract__title: 'Contract.title'
            },'statePrefix'),
            ...mapFields('ncr',{
                newContractRequestSaved: 'newContractRequestSaved',
                newContractRequestUnsaved: 'newContractRequestUnsaved',
                updatedContractRequestSaved: 'updatedContractRequestSaved',
                updatedContractRequestUnsaved: 'updatedContractRequestUnsaved'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('paymentterm', {
                allBuyerPaymentTerms: 'allBuyerPaymentTerms',
                allSupplierPaymentTerms: 'allSupplierPaymentTerms'
            }),
            ...mapGetters('productgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('salescolleague', {
                allSalesColleagues: 'allSalesColleagues'
            }),
            ...mapGetters('shippingport', {
                allShippingDischargePorts: 'allShippingDischargePorts',
                allShippingLoadingPorts: 'allShippingLoadingPorts'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('spec',{
                allSpecs: 'allSpecs',
                allCleanedSpecs: 'allCleanedSpecs',
                findSpecById: 'findSpecById'
            }),
            ...mapGetters('supplier', {
                allSuppliers: 'allSuppliers',
                findSupplierById: 'findSupplierById'
            }),
            ...mapGetters('user',{
                User: 'User'
            }),
            ...mapGetters([
                'calendarWeeks',
                'certifications',
                'containerSizes',
                'currencies',
                'incoterms',
                'ncrStatuses',
                'offices',
                'productGroups',
                'salesTypes'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_ncr')
            },
            //this is temporary remove later when casl is applied
            // canApproveReject () {
            //     return ['teesc@apptimber.com','weiwen@apptimber.com','vanjoe@apptimber.com'].includes(this.User.email)
            // },
            computedNcrDateFormatted() {
                return formatDate(this.Ncr__ncrdate)
            },
            computedNcrPreferredEtaFormatted() {
                return formatDate(this.Ncr__etadate)
            },
            computedNcrPreferredEtdFormatted() {
                return formatDate(this.Ncr__etddate)
            },
            contractHeaders() {
                return [
                    {
                        id: 1,
                        text: this.$t('message.salesColleague'),
                        value: 'Contract.Salesperson.name',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap',
                        rowClass: 'vertical-middle'
                    },
                    {
                        id: 2,
                        text: this.$t('message.contractNo'),
                        value: 'Contract.title',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 3,
                        text: this.$t('message.date'),
                        value: 'Contract.contractdate',
                        sortable: true,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 4,
                        text: this.$t('message.buyer'),
                        value: 'Contract.Customer.title',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 5,
                        text: this.$t('message.supplier'),
                        value: 'Contract.Supplier.title',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 6,
                        text: this.$t('message.species'),
                        value: 'Contract.species',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 7,
                        text: this.$t('message.grade'),
                        value: 'Contract.grade',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 8,
                        text: this.$t('message.size'),
                        value: 'Contract.size',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 9,
                        text: this.$t('message.qty'),
                        value: 'Contract.containersize_id',
                        sortable: false,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 10,
                        text: this.$t('message.cost'),
                        value: 'Contract.cost',
                        sortable: false,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 11,
                        text: this.$t('message.value'),
                        value: 'Contract.amount',
                        sortable: false,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                ]
            },
            filteredIncoterms() {
                return this.incoterms.filter(i => {
                    return [5,9].includes(i.Incoterm.id) == false
                })
            },
            filteredOffices() {
                return this.offices.filter(i => {
                    return [1,20,21,22].includes(i.Office.id) == true
                })
            },
            ncrHeaders() {
                return [
                    {
                        id: 1,
                        text: this.$t('message.salesColleague'),
                        value: 'Ncr.Salescontact.name',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 2,
                        text: this.$t('message.ncrNo'),
                        value: 'Ncr.title',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 3,
                        text: this.$t('message.date'),
                        value: 'Ncr.ncrdate',
                        sortable: true,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 4,
                        text: this.$t('message.buyer'),
                        value: 'Ncr.Customer.title',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 5,
                        text: this.$t('message.supplier'),
                        value: 'Ncr.Supplier.title',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 6,
                        text: this.$t('message.species'),
                        value: 'Ncr.species',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 7,
                        text: this.$t('message.grade'),
                        value: 'Ncr.grade',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 8,
                        text: this.$t('message.size'),
                        value: 'Ncr.size',
                        sortable: true,
                        searchable: true,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 9,
                        text: this.$t('message.qty'),
                        value: 'Ncr.containersize_id',
                        sortable: false,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 10,
                        text: this.$t('message.cost'),
                        value: 'Ncr.cost',
                        sortable: false,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 11,
                        text: this.$t('message.value'),
                        value: 'Ncr.amount',
                        sortable: false,
                        searchable: false,
                        class: 'grey lighten-3 pa-1 text-no-wrap'
                    },
                ]
            },
            sortedCalendarWeeks: function () {
                const currentWeek = getCurrentWeek();
                let nextWeeks = []
                let previousWeeks = []
                this.calendarWeeks.forEach(cw => {
                    if(cw.Calendarweek.id >= currentWeek){
                        nextWeeks.push(cw)
                    } else {
                        previousWeeks.push(cw)
                    }
                })

                //check if current year has week 53
                if(yearHasExtraWeek(getCurrentYear)){
                    const calendarObj = {Calendarweek: {id:53, title: 'WK 53', dateRange: ''}}
                    nextWeeks.push(calendarObj)
                }

                //generate date range values
                nextWeeks.forEach((item, idx) => {
                    nextWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeek(item.Calendarweek.id)
                })
                previousWeeks.forEach((item, idx) => {
                    previousWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeekWithYear(item.Calendarweek.id, getNextYear())
                })
                const weeks = [...nextWeeks, ...previousWeeks]
                return weeks
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_ncr')
            },
        },
        methods: {
            ...mapActions('buyer',{
                getAllActiveBuyers: 'getAllActiveBuyers'
            }),
            ...mapActions('country',{
                getAllCountries: 'getAllCountries'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('ncr',{
                approveNcrById: 'approveNcrById',
                cancelNcrById: 'cancelNcrById',
                convertNcrToContractById: 'convertNcrToContractById',
                copyContractToNcr: 'copyContractToNcr',
                copyNcrToNcr: 'copyNcrToNcr',
                createNcr: 'createNcr',
                getNcrToUpdateById: 'getNcrToUpdateById',
                rejectNcrById: 'rejectNcrById',
                resetAllActiveNcrs: 'resetAllActiveNcrs',
                resetCurrentNcr: 'resetCurrentNcr',
                resetUpdateNcr: 'resetUpdateNcr',
                submitNcrById: 'submitNcrById',
                updateNcr: 'updateNcr',
            }),
            ...mapActions('ncritem',{
                copyContractFscToNcrItems: 'copyContractFscToNcrItems',
                // copyNcrFscToNcrItems: 'copyNcrFscToNcrItems',
                copyContractItemsToNcrItems: 'copyContractItemsToNcrItems',
                copyNcrItemsToNcrItems: 'copyNcrItemsToNcrItems',
                getItemsByNcr: 'getItemsByNcr',
                resetNcrItems: 'resetNcrItems',
            }),
            ...mapActions('paymentterm', {
                getAllPaymentTerms: 'getAllPaymentTerms'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('salescolleague', {
                getAllSalesColleagues: 'getAllSalesColleagues',
            }),
            ...mapActions('spec', {
                getAllSpecs: 'getAllSpecs',
                getAllCleanedSpecs: 'getAllCleanedSpecs'
            }),
            ...mapActions('supplier', {
                resetAllSuppliers: 'resetAllSuppliers',
                getAllSuppliers: 'getAllSuppliers'
            }),
            ...mapActions('shippingport', {
                getAllShippingPorts: 'getAllShippingPorts',
            }),
            addNcr() {
                this.validateNcrItems = true
                let _this = this
                setTimeout(function(){
                    if(_this.validNcrItems) {
                        if(_this.$refs.ncrForm.validate()) {
                            _this.loading.add = true
                            _this.createNcr()
                                .then((response) => {
                                    if (response.status == 'success') {
                                        _this.$toast.success(_this.$t('message.successes.ncrAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )
                                        _this.loading.add = false
                                        _this.newContractRequestUnsaved = false
                                        _this.newContractRequestSaved = true
                                        _this.resetCurrentNcr().then(() => {
                                            _this.resetAllActiveNcrs().then(() => {
                                                router.push({
                                                    name: 'update_ncr',
                                                    params: {ncrId: response.ncr_id}
                                                }).catch(err => {
                                                })
                                            })
                                        })
                                    } else {
                                        _this.$toast.error(_this.$t('message.errors.ncrNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.loading.add = false
                                    }
                                })
                                .catch(() => {
                                    _this.$toast.error(_this.$t('message.errors.ncrNotAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    _this.loading.add = false
                                })
                                .finally(() => {
                                    _this.loading.add = false
                                })
                        } else {
                            _this.$toast.error(_this.$t('message.errors.ncrNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            _this.loading.add = false
                            scrollToFirstFormValidationError()
                        }
                    } else {
                        _this.$toast.error(_this.$t('message.errors.ncrNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        _this.loading.add = false
                        scrollToFirstFormValidationError()
                        // setTimeout(this.addNcr,300)
                    }
                },1000)
                // if(this.validNcrItems) {
                //     if(this.$refs.ncrForm.validate()) {
                //         this.createNcr()
                //             .then((response) => {
                //                 if (response.status == 'success') {
                //                     this.$toast.success(this.$t('message.successes.ncrAdded'),
                //                         {
                //                             classes: ['icon-float-left'],
                //                             icon: 'check_circle_outline'
                //                         }
                //                     )
                //                     this.loading.add = false
                //                     this.newContractRequestUnsaved = false
                //                     this.newContractRequestSaved = true
                //                     this.resetCurrentNcr().then(() => {
                //                         this.resetAllActiveNcrs().then(() => {
                //                             router.push({
                //                                 name: 'update_ncr',
                //                                 params: {ncrId: response.ncr_id}
                //                             }).catch(err => {
                //                             })
                //                         })
                //                     })
                //                 } else {
                //                     this.$toast.error(this.$t('message.errors.ncrNotAdded'),
                //                         {
                //                             classes: ['icon-float-left'],
                //                             icon: 'error_outline'
                //                         }
                //                     )
                //                     this.loading.add = false
                //                 }
                //             })
                //             .catch(() => {
                //                 this.$toast.error(this.$t('message.errors.ncrNotAdded'),
                //                     {
                //                         classes: ['icon-float-left'],
                //                         icon: 'error_outline'
                //                     }
                //                 )
                //                 this.loading.add = false
                //             })
                //             .finally(() => {
                //                 this.loading.add = false
                //             })
                //     } else {
                //         this.$toast.error(this.$t('message.errors.ncrNotAdded'),
                //             {
                //                 classes: ['icon-float-left'],
                //                 icon: 'error_outline'
                //             }
                //         )
                //         this.loading.add = false
                //     }
                // } else {
                //     this.$toast.error(this.$t('message.errors.ncrNotAdded'),
                //         {
                //             classes: ['icon-float-left'],
                //             icon: 'error_outline'
                //         }
                //     )
                //     this.loading.add = false
                //     // setTimeout(this.addNcr,300)
                // }
            },
            async approveNcr (val){
                if (await this.$root.$confirm(this.$t('message.approveNcr'), this.$t('message.confirmations.continueNcrApproval'), {color: 'success'})) {
                    this.loading.approve = true
                    this.approveNcrById(val)
                        .then(status => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.ncrApproved'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.approve = false
                            } else {
                                this.$toast.error(this.$t('message.errors.ncrNotApproved'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.ncrNotApproved'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.approve = false
                        })
                }
            },
            buyerChanged () {
                if( this.Ncr__customer_id != null ){
                    let buyer = this.allBuyers.find(b => b.Customer.id == this.Ncr__customer_id)
                    if(buyer.Customer.paymentstatement_id != null){
                        this.Ncr__paymentstatement_id = buyer.Customer.paymentstatement_id
                    }
                }
            },
            async cancelNcr (val) {
                if(await this.$root.$confirm(this.$t('message.cancelNcr'), this.$t('message.confirmations.continueNcrCancelAction'), {color: 'orange'})) {
                    this.cancelNcrById(val)
                        .then(status => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.ncrDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                router.push({name: 'ncr_listing'}).catch(err => {
                                })
                                this.loading.cancel = false
                            } else {
                                this.$toast.error(this.$t('message.errors.ncrNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.ncrNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.cancel = false
                        })
                }
            },
            checkEutrComplianceRequired(countryId) {
                if(countryId != null) {
                    return new Promise((resolve, reject) => {
                        api
                            .get('/countries/' + countryId, {
                                params: {
                                    fields: [
                                        'countries.eutr_compliance_required AS countries__eutr_compliance_required'
                                    ]
                                }
                            })
                            .then((response) => {
                                const data = response.data.data
                                if (data && data[0].Country.eutr_compliance_required == 1) {
                                    this.Ncr__eutr_compliance_required = 1
                                }
                            })
                            .catch((e) => {
                                log(e)
                            })
                    })
                } else {
                    return false
                }
            },
            contractSpecies (ids) {
                let array = []
                if(ids != null) {
                    ids.forEach((id) => {
                        let species = this.findSpeciesById(id)
                        if (species) {
                            array.push(species.Species.title)
                        }
                    })
                }
                return array
            },
            convertNcrToContract (val) {
                if (this.newContractNo == null) {
                    this.dialogs.error_message = this.$t('message.errors.contractNumberCannotBeEmpty');
                    this.dialogs.error = true
                } else {
                    this.loading.convert = true
                    let payload = {
                        prefix: this.statePrefix,
                        ncr_id: val,
                        contract_number: this.newContractNo,
                        setContract: true
                    }
                    this.convertNcrToContractById(payload)
                        .then(status => {
                            if (status == 'done') {
                                this.loading.convert = false
                                this.newContractNo = null
                                this.convertDialog = false
                                this.$toast.success(this.$t('message.successes.ncrConverted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loadNcrById(this.ncrId)
                            } else {
                                this.loading.convert = false
                                this.newContractNo = null
                                this.convertDialog = false
                                this.$toast.error(this.$t('message.errors.ncrNotConverted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.loading.convert = false
                            this.newContractNo = null
                            this.convertDialog = false
                            this.$toast.error(this.$t('message.errors.ncrNotConverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            copyContract() {
                this.itemsCopied = false
                let payload = {
                    prefix: this.statePrefix,
                    contract: this.selectedContract
                }
                this.loading.copy = true
                this.copyContractToNcr(payload)
                    .then(() => {
                        let contractId = this.selectedContract[0]['Contract']['id']
                        if(contractId){
                            let payloadNcrItems = {
                                prefix: this.statePrefix,
                                contractId: contractId,
                                cleanedSpecs: this.allCleanedSpecs,
                                products: this.allProductGroups
                            }
                            this.copyContractItemsToNcrItems(payloadNcrItems)
                                .then(() => {
                                    let fsc = this.selectedContract[0]['Contract']['fsc']
                                    let payloadFsc = {
                                        prefix: this.statePrefix,
                                        fsc: fsc
                                    }
                                    this.itemsCopied = true
                                    this.copyContractFscToNcrItems(payloadFsc)
                                    this.checkEutrComplianceRequired(this.Ncr__destination_id)
                                    this.selectedContract = []
                                    this.loading.copy = false
                                    this.selectContractDialog = false
                                    this.reloadDescriptions = true
                                })
                                .catch(() => {
                                    this.loading.copy = false
                                    this.$toast.error(this.$t('message.errors.cannotCopyContract'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        }
                    })
                    .catch(() => {
                        this.loading.copy = false
                        this.$toast.error(this.$t('message.errors.cannotCopyContract'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            },
            copyNcr() {
                this.loading.copyNcr = true
                this.itemsCopied = false
                let payload = {
                    prefix: this.statePrefix,
                    ncr: this.selectedNcr
                }
                this.copyNcrToNcr(payload)
                    .then(() => {
                        let ncrId = this.selectedNcr[0]['Ncr']['id']
                        if(ncrId){
                            let payloadNcrItems = {
                                prefix: this.statePrefix,
                                ncrId: ncrId,
                                cleanedSpecs: this.allCleanedSpecs,
                                products: this.allProductGroups
                            }
                            this.copyNcrItemsToNcrItems(payloadNcrItems)
                                .then(() => {
                                    let fsc = this.selectedNcr[0]['Ncr']['fsc']
                                    // let payloadFsc = {
                                    //     prefix: this.statePrefix,
                                    //     fsc: fsc
                                    // }
                                    this.itemsCopied = true
                                    // this.copyNcrFscToNcrItems(payloadFsc)
                                    this.checkEutrComplianceRequired(this.Ncr__destination_id)

                                    //check valid products
                                    //check valid sizes
                                    //check valid measurement system

                                    this.selectedNcr = []
                                    this.loading.copyNcr = false
                                    this.selectNcrDialog = false
                                })
                                .catch(() => {
                                    this.loading.copyNcr = false
                                    this.$toast.error(this.$t('message.errors.cannotCopyNcr'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        }
                    })
                    .catch(() => {
                        this.loading.copyNcr = false
                        this.$toast.error(this.$t('message.errors.cannotCopyNcr'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            },
            getCurrency(currency_id) {
                return this.currencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            filterResults () {
                this.loading.filterResults = true
                this.tableOptions.page = 1
                let _this = this
                setTimeout(function(){
                    _this.loading.filterResults = false
                },700)
            },
            formatDate,
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getCurrentWeek,
            getCurrentYear,
            getNextYear,
            getDateRangeOfWeek,
            getDateRangeOfWeekWithYear,
            getContainerSize (containersize_id) {
                let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == containersize_id)
                if( container ) {
                    return container?.Containersize.title
                } else {
                    return null
                }
            },
            getSalesContactContracts(){
                return new Promise((resolve, reject) => {
                    api
                        .get("/contracts", {
                            params: {
                                conditions: [
                                    {
                                        field: 'contracts.salescontact_id',
                                        value: this.Ncr__salescontact_id
                                    },
                                    {
                                        field: 'contracts.salestype_id',
                                        value: [1,2,5]
                                    },
                                    {
                                        field: 'contracts.salestatus_id',
                                        value: [1,2]
                                    },
									{
										field: 'contracts.contractdate',
										greaterThanOrEqual: true,
                                        value: '2022-01-01'
                                    }
                                ],
                                order: [
                                    {
                                        field: 'contracts.contractdate',
                                        direction: 'DESC'
                                    },
                                ]
                            }
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getSalesContactNcrs(){
                return new Promise((resolve, reject) => {
                    api
                        .get("/ncrs", {
                            params: {
                                conditions: [
                                    {
                                        field: 'ncrs.salescontact_id',
                                        value: this.Ncr__salescontact_id
                                    },
                                    {
                                        field: 'ncrs.salestype_id',
                                        value: [1,2,5]
                                    },
                                    {
                                        field: 'ncrs.status_id',
                                        value: [1,2,3]
                                    }
                                ],
                                order: [
                                    {
                                        field: 'ncrs.ncrdate',
                                        direction: 'DESC'
                                    },
                                ]
                            }
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getStatus (val) {
                let status = this.ncrStatuses.find(s => s.Ncrstatus.id == this.Ncr__status_id)
                return status?.Ncrstatus.title
            },
            incotermChanged (val) {
                if(val == 1) this.Ncr__incotermport = 1
                if(val == 2) this.Ncr__incotermport = 0
                if(val == 3) this.Ncr__incotermport = 0
            },
            loadNcrById (val) {
                this.loader = true
                this.getNcrToUpdateById(val)
                    .then(() => {

                        this.$title =  "NCR: " + this.Ncr__title

                        this.loading.buyers = true
                        this.filteredBuyers = []
                        let buyers = this.allBuyers.filter(b => b.Customer.salescontact_id == this.Ncr__salescontact_id)
                        let buyer = buyers.find(b => b.Customer.id == this.Ncr__customer_id)
                        if(buyer == null){
                            //broaden filter
                            //get country of sales contact
                            let salesColleague = this.allSalesColleagues.find(s => s.Salesperson.id == this.Ncr__salescontact_id)
                            if(salesColleague) {
                                buyers = [];
                                buyers = this.allBuyers.filter(b => b.Company.country_id == salesColleague.Country.id)
                                buyer = buyers.find(b => b.Customer.id == this.Ncr__customer_id)
                                if(buyer == null){
                                    buyers = this.allBuyers
                                }
                            }
                            //for export colleagues add SDN BHD to list
                            if([49,14,51].includes(this.Ncr__salescontact_id)){
                                let tempBuyer = this.allBuyers.find(b => b.Customer.id == 100)
                                if(tempBuyer) {
                                    this.filteredBuyers.push(tempBuyer)
                                } else {
                                    this.filteredBuyers.push({
                                        Customer: {
                                            id: 100,
                                            otsname: 'APP MY',
                                            paymentstatement_id: null
                                        }
                                    })
                                }
                            }
                        }
                        this.filteredBuyers = buyers

                        this.loading.buyers = false
                        let payload = {
                            ncr_id: val,
                            prefix: this.statePrefix
                        }
                        this.getItemsByNcr(payload)
                            .then(() => {
                                this.loader = false
                                this.updatedContractRequestUnsaved = false
                                this.itemsLoaded = true
                            })
                            .catch(()=>{
                                this.loader = false
                                this.updatedContractRequestUnsaved = false
                            })
                            .then(()=>{
                                this.loader = false
                                this.updatedContractRequestUnsaved = false
                                this.itemsLoaded = true
                            })
                    })
            },
            ncrList() {
                let tab = window.open('/v1/ncrs/overview','_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            newNcr() {
                let tab = window.open('/v1/ncrs/add/' + this.Ncr__salescontact_id + '/' + this.Ncr__customer_id,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            openSelectContractDialog() {
                if(this.Ncr__salescontact_id == null){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.pleaseSelectSalesColleagueFirst')
                } else {
                    this.selectContractDialog = true
                }
            },
            openSelectNcrDialog() {
                if(this.Ncr__salescontact_id == null){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.pleaseSelectSalesColleagueFirst')
                } else {
                    this.selectNcrDialog = true
                }
            },
            async rejectNcr(val) {
                if (await this.$root.$confirm(this.$t('message.rejectNcr'), this.$t('message.confirmations.continueNcrReject'), {color: 'error'})) {
                    this.loading.reject = true
                    this.rejectNcrById(val)
                        .then(status => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.ncrRejected'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.reject = false
                            } else {
                                this.$toast.error(this.$t('message.errors.ncrNotRejected'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.wprNotRejected'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.reject = false
                        })
                }
            },
            salesColleagueChanged() {
                this.loading.buyers = true
                this.filteredBuyers = []
                this.filteredBuyers = this.allBuyers.filter(b => b.Customer.salescontact_id == this.Ncr__salescontact_id)
                //for export colleagues add SDN BHD to list
                if([49,14,51].includes(this.Ncr__salescontact_id)){
                    let tempBuyer = this.allBuyers.find(b => b.Customer.id == 100)
                    if(tempBuyer) {
                        this.filteredBuyers.push(tempBuyer)
                    } else {
                        this.filteredBuyers.push({
                            Customer: {
                                id: 100,
                                otsname: 'APP MY',
                                paymentstatement_id: null
                            }
                        })
                    }
                }
                this.loading.buyers = false

                //assign company if sales is serviced type
                if(this.Ncr__salestype_id == 5) {
                    let salesColleague = this.allSalesColleagues.find(sc => sc.Salesperson.id == this.Ncr__salescontact_id)
                    if(salesColleague){
                        let countryId = salesColleague.Country.id
                        switch (countryId){
                            case 1: //MY
                                this.Ncr__office_id = 1
                                break
                            case 2: //TH
                                this.Ncr__office_id = 20
                                break
                            case 3: //VN
                                this.Ncr__office_id = 22
                                break
                            case 4: //ID
                                this.Ncr__office_id = 21
                                break
                            default:
                                this.Ncr__office_id = 1
                        }
                    }
                }

                this.loading.contracts = true
                this.loading.ncrs = true
                this.getSalesContactContracts()
                    .then(data => {
                        this.filteredContracts = data
                        this.loading.contracts = false
                        this.getSalesContactNcrs()
                            .then((data)=>{
                                this.filteredNcrs = data
                                this.loading.ncrs = false
                            })
                            .catch(()=>{
                                this.loading.ncrs = false
                            })
                    })
                    .catch(() => {
                        this.loading.contracts = false
                    })
            },
            salesTypeChanged(salesTypeId) {
                if(salesTypeId == 5) {
                    let salesColleague = this.allSalesColleagues.find(sc => sc.Salesperson.id == this.Ncr__salescontact_id)
                    if(salesColleague){
                        let countryId = salesColleague.Country.id
                        switch (countryId){
                            case 1: //MY
                                this.Ncr__office_id = 1
                                break
                            case 2: //TH
                                this.Ncr__office_id = 20
                                break
                            case 3: //VN
                                this.Ncr__office_id = 22
                                break
                            case 4: //ID
                                this.Ncr__office_id = 21
                                break
                            default:
                                this.Ncr__office_id = 1
                        }
                    }
                }
            },
            saveNcr() {
                this.validateNcrItems = true
                let _this = this
                setTimeout(function(){
                    if(_this.validNcrItems) {
                        if (_this.$refs.ncrForm.validate()) {
                            _this.loading.save = true
                            _this.updateNcr()
                                .then((status) => {
                                    if (status == 'done') {
                                        _this.$toast.success(_this.$t('message.successes.ncrUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'check_circle_outline'
                                            }
                                        )

                                        if(_this.previewDialog) _this.previewDialog = false

                                        let payload = {
                                            ncr_id: _this.Ncr__id,
                                            prefix: _this.statePrefix
                                        }
                                        _this.getItemsByNcr(payload)
                                            .then(() => {
                                                _this.loading.save = false
                                                _this.updatedContractRequestUnsaved = false
                                                _this.itemListKey = Math.floor(Math.random() * 100)
                                                _this.itemsLoaded = true
                                            })
                                            .catch(() => {
                                                _this.loading.save = false
                                                _this.updatedContractRequestUnsaved = false
                                            })
                                            .then(() => {
                                                _this.loading.save = false
                                                _this.updatedContractRequestUnsaved = false
                                                _this.itemListKey = Math.floor(Math.random() * 100)
                                                _this.itemsLoaded = true
                                            })
                                    } else {
                                        _this.$toast.error(_this.$t('message.errors.ncrNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                        _this.loading.save = false
                                    }
                                })
                                .catch(() => {
                                    _this.$toast.error(_this.$t('message.errors.ncrNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                    _this.loading.save = false
                                })
                        } else {
                            _this.$toast.error(_this.$t('message.errors.ncrNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            _this.loading.save = false
                            scrollToFirstFormValidationError()
                        }
                    } else {
                        _this.$toast.error(_this.$t('message.errors.ncrNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        _this.loading.save = false
                        scrollToFirstFormValidationError()
                        // setTimeout(_this.saveNcr,300)
                    }
                }, 1000)
                // if(this.validNcrItems) {
                //     if (this.$refs.ncrForm.validate()) {
                //         this.updateNcr().then((status) => {
                //             if (status == 'done') {
                //                 this.$toast.success(this.$t('message.successes.ncrUpdated'),
                //                     {
                //                         classes: ['icon-float-left'],
                //                         icon: 'check_circle_outline'
                //                     }
                //                 )
                //                 let payload = {
                //                     ncr_id: this.Ncr__id,
                //                     prefix: this.statePrefix
                //                 }
                //                 this.getItemsByNcr(payload)
                //                     .then(() => {
                //                         this.loading.save = false
                //                         this.updatedContractRequestUnsaved = false
                //                     })
                //                     .catch(() => {
                //                         this.loading.save = false
                //                         this.updatedContractRequestUnsaved = false
                //                     })
                //                     .then(() => {
                //                         this.loading.save = false
                //                         this.updatedContractRequestUnsaved = false
                //                     })
                //             } else {
                //                 this.$toast.error(this.$t('message.errors.ncrNotUpdated'),
                //                     {
                //                         classes: ['icon-float-left'],
                //                         icon: 'error_outline'
                //                     }
                //                 )
                //                 this.loading.save = false
                //             }
                //         })
                //     } else {
                //         this.$toast.error(this.$t('message.errors.ncrNotUpdated'),
                //             {
                //                 classes: ['icon-float-left'],
                //                 icon: 'error_outline'
                //             }
                //         )
                //         this.loading.save = false
                //     }
                // } else {
                //     setTimeout(this.saveNcr,300)
                // }
            },
            setItemsValidity(val){
                this.validNcrItems = val
                this.validateNcrItems = false
            },
            async submitNcrForApproval (val) {
                if (await this.$root.$confirm(this.$t('message.submitNcr'), this.$t('message.confirmations.continueNcrSubmit'), {color: 'orange'})) {
                    this.loading.submit = true
                    this.submitNcrById(val)
                        .then(status => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.ncrSubmitted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.submit = false
                            } else {
                                this.$toast.error(this.$t('message.errors.ncrNotSubmitted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.ncrNotSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                        .finally(() => {
                            this.loading.submit = false
                            this.$vuetify.goTo( this.$refs.actionsRow )
                        })
                }
            },
            viewPdf() {
                let document = encodeURIComponent(this.Ncr__title)
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/ncrs/print/'
                    + this.ncrId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        watch: {
            convertDialog (val) {
                if( !val ) this.newContractNo = null;
            },
            Ncr__salescontact_id(val) {
                //specific to S.Breier 2021-08-03
                if([49].includes(val)) this.Ncr__is_export = 1
            }
        },
        beforeMount () {
            this.resetCurrentNcr()
            this.resetUpdateNcr()
            this.resetNcrItems(this.statePrefix);
        },
        created () {
            if(this.allShippingDischargePorts.length == 0 || this.allShippingLoadingPorts.length == 0) this.getAllShippingPorts()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allProductGroups.length == 0) this.getAllProductGroups()
            if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
            if(this.allBuyers.length == 0) this.getAllActiveBuyers()
            if(this.allCountries.length == 0) this.getAllCountries()
            if(this.allBuyerPaymentTerms.length == 0) this.getAllPaymentTerms()
            if(this.allSuppliers.length == 0) this.getAllSuppliers()
            if(this.allSalesColleagues.length == 0){
                this.getAllSalesColleagues()
                    .then(() => {
                        if(this.$route.matched.some(({name}) => name === 'add_ncr')) {
                            let promise = new Promise((resolve, reject) => {
                                let salesColleague = this.allSalesColleagues.find(sc => sc.Salesperson.userid == this.User.email)
                                if (salesColleague) {
                                    this.Ncr__salescontact_id = salesColleague.Salesperson.id
                                }
                                resolve('done')
                            })
                            promise.then(() => {
                                if(this.Ncr__salescontact_id != null) {
                                    this.loading.buyers = true
                                    this.filteredBuyers = []
                                    this.filteredBuyers = this.allBuyers.filter(b => b.Customer.salescontact_id == this.Ncr__salescontact_id)
                                    //for export colleagues add SDN BHD to list
                                    if([49,14,51].includes(this.Ncr__salescontact_id)){
                                        let tempBuyer = this.allBuyers.find(b => b.Customer.id == 100)
                                        if(tempBuyer) {
                                            this.filteredBuyers.push(tempBuyer)
                                        } else {
                                            this.filteredBuyers.push({
                                                Customer: {
                                                    id: 100,
                                                    otsname: 'APP MY',
                                                    paymentstatement_id: null
                                                }
                                            })
                                        }
                                    }
                                    this.loading.buyers = false
                                    this.getSalesContactContracts()
                                        .then(data => {
                                            this.filteredContracts = data
                                            this.loading.contracts = false
                                            this.getSalesContactNcrs()
                                                .then((data)=>{
                                                    this.filteredNcrs = data
                                                    this.loading.ncrs = false
                                                })
                                                .catch(()=>{
                                                    this.loading.ncrs = false
                                                })
                                        })
                                        .catch(() => {
                                            this.loading.contracts = false
                                        })
                                } else {
                                    this.loading.contracts = false
                                }
                            })
                        }
                    })
            }

            if(this.$route.matched.some(({name}) => name === 'update_ncr')){
                this.loadNcrById(this.ncrId)
            } else if(this.$route.matched.some(({name}) => name === 'add_ncr')){
                this.resetCurrentNcr();
                this.resetNcrItems(this.statePrefix);
                if(this.salesContactId != null){
                    this.Ncr__salescontact_id = parseInt(this.salesContactId)
                    this.loading.buyers = true
                    this.filteredBuyers = []
                    this.filteredBuyers = this.allBuyers.filter(b => b.Customer.salescontact_id == this.salesContactId)
                    //for export colleagues add SDN BHD to list
                    if([49,14,51].includes(this.Ncr__salescontact_id)){
                        let tempBuyer = this.allBuyers.find(b => b.Customer.id == 100)
                        if(tempBuyer) {
                            this.filteredBuyers.push(tempBuyer)
                        } else {
                            this.filteredBuyers.push({
                                Customer: {
                                    id: 100,
                                    otsname: 'APP MY',
                                    paymentstatement_id: null
                                }
                            })
                        }
                    }
                    this.loading.buyers = false
                    this.getSalesContactContracts()
                        .then(data => {
                            this.filteredContracts = data
                            this.loading.contracts = false
                        })
                        .catch(() => {
                            this.loading.contracts = false
                        })
                    if(this.buyerId != null){
                        this.Ncr__customer_id = parseInt(this.buyerId)
                    }
                }
            }
        },
        mounted () {
            this.$store.watch(
                function(state) {
                    return state.appic.ncr.update.Ncr
                },
                () => {
                    this.updatedContractRequestUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.ncr.current.Ncr
                },
                () => {
                    this.newContractRequestUnsaved = true
                },
                { deep: true }
            )
            if(this.$route.matched.some(({name}) => name === 'update_ncr')){
                // setInterval(() => {
                //     this.$title =  "CR: " + this.Ncr__title
                // }, 2000)
            } else {
                this.$title =  this.$t('message.titles.newContractRequest')
                this.Ncr__ncrdate = new Date().toISOString().substr(0,10)
            }
        }
    }
</script>

<style>
.force-text-left .v-text-field__slot input {
    text-align: left !important;
}

.v-dialog {
    min-width: 200px !important;
}

.wrap-text {
    white-space: normal;
}

.font-xs {
    font-size: small;
}
.v-data-table tbody td {
    vertical-align: middle !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
#contract_list .v-data-table__wrapper table > tbody tr td.text-start {
    vertical-align: middle !important;
}
#ncr_list .v-data-table__wrapper table > tbody tr td.text-start {
    vertical-align: middle !important;
}
.v-data-table-header tr th:first-child {
    background-color: #EEEEEE !important;
    border-bottom-color: #EEEEEE !important;
}
</style>