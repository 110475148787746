<template>
    <div>
        <v-btn
            :loading="loading.ncr"
            small
            class="ml-2 lighten-2"
            v-if="updateMode && !listMode"
            @click.stop="printNcr()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.preview') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printNcr()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.ncr"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.pdf') }}</span>
        </v-list-item>
        <PrintNcrBlob
            :ncr-id="ncrId"
            :key="updateNcrKey"
            @pdf-uploading="ncrUploading"
            @pdf-uploaded="ncrUploaded"
            @pdf-not-uploaded="ncrNotUploaded"
            v-if="hasNcr"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";

const PrintNcrBlob = () => import("Views/v1/ncrs/PrintNcrBlob");
export default {
    name: "PrintNcrButton",
    props: ['ncrId','updateMode','listMode'],
    components: {PrintNcrBlob},
    data() {
        return {
            currentNcrPdf: null,
            hasNcr: false,
            loading: {
                ncr: false,
            },
            updateNcrKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/ncrs/" + this.ncrId, {
                        params: {
                            fields: [
                                'ncrs.location AS ncrs__location'
                            ]
                        }
                    })
                    .then(response => {
                        const ncr = response.data.data[0]
                        if(ncr){
                            this.currentNcrPdf = ncr.Ncr.location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        ncrNotUploaded() {
            this.loading.ncr = false
            this.hasNcr = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        ncrUploaded(url) {
            this.currentNcrPdf = url
            this.loading.ncr = false
            this.hasNcr = false
            this.viewFile(this.currentNcrPdf)
        },
        ncrUploading() {
            this.loading.ncr = true
        },
        printNcr() {
            this.loading.ncr = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentNcrPdf != null){
                        this.viewFile(this.currentNcrPdf)
                            .then(() => this.loading.ncr = false)
                            .catch(() => this.loading.ncr = false)
                    } else {
                        this.hasNcr = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.ncr = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        }
    }
}
</script>

<style scoped>

</style>